import {Controller} from "stimulus";

export default class extends Controller {

  static targets = [ "types", "valid_period_type" ];


  connect() {
    console.log("coupon form connect");
    this.change_types_display();
    this.change_valid_period_type_display();
  }

  types_change() {
    console.log("types_change");
    this.change_types_display();
  }

  change_types_display() {
    if(this.typesTarget.value === 'money_off') {
      $('.coupon_discount').hide();
      $('.coupon_money_off_amount').show();
    } else if (this.typesTarget.value === 'discount') {
      $('.coupon_discount').show();
      $('.coupon_money_off_amount').hide();
    } else {
      $('.coupon_discount').hide();
      $('.coupon_money_off_amount').hide();
    }
  }

  valid_period_type_change() {
    console.log("valid_period_type_change");
    this.change_valid_period_type_display();
  }

  change_valid_period_type_display() {
    let select_value = this.valid_period_typeTarget.value;
    if(select_value === 'period') {
      $('.coupon_start_at').show();
      $('.coupon_end_at').show();
      $('.coupon_x_days').hide();
    } else if (select_value === 'x_days') {
      $('.coupon_start_at').hide();
      $('.coupon_end_at').hide();
      $('.coupon_x_days').show();
    } else {
      $('.coupon_start_at').hide();
      $('.coupon_end_at').hide();
      $('.coupon_x_days').hide();
    }

  }
}

import {Controller} from "stimulus";

export default class extends Controller {

  static targets = [ "no_limit_num" ];

  connect() {
    console.log("coupon rule form connect");
    this.set_num_display()
  }

  change() {
    console.log("change");
    this.set_num_display()
  }

  set_num_display() {
    let checked = $("#coupon_rule_no_limit_num").is(':checked');
    console.log(checked);
    if (checked) {
      $('.coupon_rule_num').hide()
    } else {
      $('.coupon_rule_num').show()
    }
  }
}

import {Controller} from "stimulus";
import {DirectUpload} from "@rails/activestorage";
import Dropzone from 'dropzone';
import { getMetaValue, toArray, findElement, removeElement, insertAfter } from "./helpers"

Dropzone.autoDiscover = false
Dropzone.prototype.defaultOptions.dictRemoveFile = "删除文件"
Dropzone.prototype.defaultOptions.dictCancelUpload = "取消上传"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

// Private
  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      console.log("addedfile", file)
      setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
    })

    this.dropZone.on("removedfile", (file) => {
      console.log("removedfile", file, file.previewElement.id)
      file.controller && removeElement(file.controller.hiddenInput)

      $.ajax({
        url: "/admin/attachments/" + file.previewElement.id,
        method: 'DELETE'
      })
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })
  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }
  get maxFiles() { return this.data.get("maxFiles") || 1 }
  get maxFileSize() { return this.data.get("maxFileSize") || 2048 }
  get acceptedFiles() { return this.data.get("acceptedFiles") }
  get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      console.log("directUpload", attributes)
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.file.previewElement.id = attributes.id;
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

// Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".progress .progress-bar").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...

function createDirectUploadController(source, file) {
  console.log("createDirectUploadController", source, file)
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  let myDropzone = new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    previewTemplate: '<div class="w-100">\n' +
      '  <div class="row">' +
      '    <div class="col-2"><img data-dz-thumbnail class="img img-fluid" /></div>\n' +
      '    <div class="col-4">\n' +
      '        <span data-dz-size />\n' +
      '    </div><div class="col-6">\n' +
      '        <span data-dz-name></span>\n' +
      '    </div>\n' +
      '  </div><div class="row">\n' +
      '    <div class="col-12 mt-2"><div class="progress"><div class="progress-bar bg-dark" style="width:0%;" ></div></div></div>' +
      '  </div>\n' +
      '</div>'
  })

  const server_attachments_text = controller.inputTarget.dataset.attachments
  if(server_attachments_text) {
    const attachments = JSON.parse(server_attachments_text)
    console.log("server attachments ", attachments);
    for (let i = 0; i < attachments.length; i++) {
      const file_attr = attachments[i];
      console.log(file_attr.service_url)
      let file = {
        name: file_attr.blob.filename,
        size: file_attr.blob.byte_size,
        url: file_attr.service_url
      };
      myDropzone.emit('addedfile', file);
      myDropzone.emit("thumbnail", file, file_attr.service_url);
      file.previewElement.id = file_attr.blob.id;
      myDropzone.emit("complete", file);
    }
  }

  return myDropzone
}

import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [ "isGorup" ]

  connect() {
    console.log("connect subject_price_set ", this.isGorupTarget.checked)
    this.changeFieldStatus()
  }

  changeSelect() {
    console.log("changeSelect",  this.isGorupTarget.checked)
    this.changeFieldStatus()
  }

  changeFieldStatus() {
    if (this.isGorupTarget.checked) {
      $('.subject_group_num').show()
      $('.subject_group_price').show()
    } else {
      $('.subject_group_price').hide()
      $('.subject_group_num').hide()
    }
  }
}

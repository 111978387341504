import 'styles/admin'

import '../js/base'
import 'admin-lte'
import '../js/admin/sidebar'

import 'select2/dist/js/select2.full'
import 'select2/dist/js/i18n/zh-CN'

require("trix")
require("@rails/actiontext")

require("chartkick")
require("chart.js")

import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [ "subjectId" ];

  connect() {
    console.log("subject items options")
  }

  change() {
    console.log("change");

    let subjectId = this.subjectIdTarget.value;
    console.log(subjectId);

    $.ajax({
      type: "GET",
      url:'/admin/subject_items/subject_items_options?subject_id='+subjectId,
      data:'text',
      success: function(data){
        $('#q_subject_item_id_eq').html(data);
      }
    })
  }
}
